export class Api {
	//DashBoard
	public static DashboardGetCountCategories = 'DashBoard/DashboardGetCountCategories'
	public static DashboardGetDataChart = 'DashBoard/DashboardGetDataChart'
	public static DashboardGetListTestSchedule = 'DashBoard/DashboardGetListTestSchedule'
	public static DashboardGetListUserOnline = 'DashBoard/DashboardGetListUserOnline'
	//Account
	public static LOGIN = 'Contact/LoginForWeb'
	public static REGISTER = 'Contact/Register'
	public static FORGETPASSWORD = 'Contact/Forgetpassword'
	public static CHANGEPASSWORD = 'Contact/ChangePassword'
	public static RESTORE_ACCOUNT = 'Contact/UnlockAccount'
	public static logOut = 'Contact/LogOut'
	public static RESTORETOKEN = 'Contact/RefeshToken'
	public static CreateUserRole = 'Contact/Create'

	// Captcha Controller
	public static ValidateCaptcha = 'Captcha/ValidatorCaptcha'
	public static getImageCaptcha = 'Captcha/GetCaptchaImage'

	// FileUpload/Download
	public static DownloadApp = 'UploadFile/DownloadApp'
	public static GetEncryptedPath = 'UploadFile/GetEncryptedPath'
	public static DownloadFile = 'UploadFile/DownloadFile'
	public static DownloadFilebyId = 'UploadFile/DownloadFilebyId'
	public static downloadFileSupport = 'UploadFile/DownloadFileSupport'
	public static GetFile = 'UploadFile/GetFile'
	public static uploadfiles = 'UploadFile/Uploadfiles'
	public static GetFileImage = 'UploadFile/GetFile'

	//Monitor
	public static MonitorGetListForceSupportByType = 'Monitor/GetListForceSupportByType'
	public static MonitorGetListWaterSourceByType = 'Monitor/GetListWaterSourceByType'
	public static MonitorGetListBusinessCustomerByType = 'Monitor/GetListBusinessCustomerByType'
	public static MonitorGetListUnit = 'Monitor/GetListUnit'
	public static MonitorGetListFireHydrant = 'Monitor/GetListFireHydrant'
	public static MonitorGetListTeam = 'Monitor/GetListTeam'
	public static MonitorGetCaseInformation = 'Monitor/GetCaseInformation'
	public static MonitorGetDevices = 'Monitor/GetDevices'
	public static MonitorDevicesUpdateStatus = 'Monitor/DevicesUpdateStatus'

	//Notification
	public static NotificationGetNotificationHead = 'Notification/GetNotificationHead'
	public static NotificationGetList = 'Notification/SYNotificationGetList'
	public static NotificationGetListWarning = 'Notification/SYNotificationGetListWarning'
	public static NotificationUpdateIsViewed = 'Notification/UpdateIsViewed'
	public static NotificationDelete = 'Notification/SYNotificationDelete'
	public static NotificationUpdatestatusView = 'Notification/UpdatestatusView'

	//Field
	public static FieldGetList = 'CACategorySPBase/CAFieldGetAllOnPageBase'
	public static FieldGetById = 'CACategorySPBase/CAFieldGetByIDBase'
	public static FieldInsert = 'CACategorySPBase/CAFieldInsertBase'
	public static FieldUpdate = 'CACategorySPBase/CAFieldUpdateBase'
	public static FieldUpdateStatus = 'CACategorySPBase/CAFieldUpdateBase'
	public static FieldDelete = 'CACategorySPBase/CAFieldDeleteBase'

	//Field
	public static HashtagGetAll = 'CATableBase/CAHashtagGetAll'
	public static HashtagGetList = 'CATableBase/CAHashtagGetAllOnPage'
	public static HashtagGetById = 'CACategorySPBase/CAHashtagGetByID'
	public static HashtagInsert = 'CATableBase/CAHashtagInsert'
	public static HashtagUpdate = 'CATableBase/CAHashtagUpdate'
	public static HashtagUpdateStatus = 'CATableBase/'
	public static HashtagDelete = 'CATableBase/CAHashtagDelete'

	//Unit
	public static UnitGetPagedList = 'CACategorySPBase/CAUnitGetAllOnPageBase'
	public static getAllTree = 'CACategorySPBase/CAUnitGetAllTreeBase'
	public static UnitGetAll = 'CACategorySPBase/CAUnitGetAllBase'
	public static UnitGetByMain = 'CACategorySPBase/CAUnitGetByMain'
	public static UnitGetDropdownUser = 'CACategorySPBase/CAUnitGetDropdownUser'
	public static UnitGetDropdownUserByUnit = 'CACategorySPBase/CAUnitGetDropdownUserByUnit'
	public static UnitGetById = 'CACategorySPBase/CAUnitGetByIDBase'
	public static UnitInsert = 'CACategorySPBase/CAUnitInsertBase'
	public static UnitUpdate = 'CACategorySPBase/CAUnitUpdateBase'
	public static UnitDeleteList = 'CACategorySPBase/CAUnitDeleteListBase'
	public static UnitDelete = 'CACategorySPBase/CAUnitDeleteBase'
	public static UnitUpdateStatus = 'CACategorySPBase/CAUnitUpdateStatus'
	public static UnitInsertUser = 'CACategorySPBase/CAUnitInsertUser'

	public static FieldExport = 'CACategorySPBase/CAFieldExportBase'

	//HanMuc
	public static HanMucInsert = 'DmHanMuc/Insert'
	public static HanMucGetList = 'DmHanMuc/GetList'
	public static HanMucDelete = 'DmHanMuc/Delete'
	public static HanMucGetById = 'DmHanMuc/GetById'
	public static HanMucUpdate = 'DmHanMuc/Update'
	public static HanMucGetDropDown = 'DmHanMuc/GetDropDown'
	public static KyHanGetDropDown = 'DmHanMuc/GetDropDownKyHan'

	//ThiTruong
	public static ThiTruongInsert = 'DmThiTruong/Insert'
	public static ThiTruongGetList = 'DmThiTruong/GetList'
	public static ThiTruongDelete = 'DmThiTruong/Delete'
	public static ThiTruongGetById = 'DmThiTruong/GetById'
	public static ThiTruongUpdate = 'DmThiTruong/Update'

	//Khoi
	public static DmKhoiInsert = 'DmKhoi/Insert'
	public static DmKhoiGetList = 'DmKhoi/GetList'
	public static DmKhoiDelete = 'DmKhoi/Delete'
	public static DmKhoiGetById = 'DmKhoi/GetById'
	public static DmKhoiUpdate = 'DmKhoi/Update'

	// Lĩnh vực
	public static DmLinhVucInsert = 'DmLinhVuc/Insert'
	public static DmLinhVucGetList = 'DmLinhVuc/GetList'
	public static DmLinhVucDelete = 'DmLinhVuc/Delete'
	public static DmLinhVucGetById = 'DmLinhVuc/GetById'
	public static DmLinhVucUpdate = 'DmLinhVuc/Update'

	//DmTaiKhoanNganHang
	public static TaiKhoanNganHangInsert = 'DmTaiKhoanNganHang/Insert'
	public static TaiKhoanNganHangGetList = 'DmTaiKhoanNganHang/GetList'
	public static TaiKhoanNganHangDelete = 'DmTaiKhoanNganHang/Delete'
	public static TaiKhoanNganHangGetById = 'DmTaiKhoanNganHang/GetById'
	public static TaiKhoanNganHangUpdate = 'DmTaiKhoanNganHang/Update'
	public static TaiKhoanNganHangGetAll = 'DmTaiKhoanNganHang/GetAll'
	public static TaiKhoanNganHangGetDropDown = 'DmTaiKhoanNganHang/GetDropDown'
	

    //KyHan
	public static KyHanInsert = 'DmKyHan/Insert'
	public static KyHanGetList = 'DmKyHan/GetList'
	public static KyHanDelete = 'DmKyHan/Delete'
	public static KyHanGetById = 'DmKyHan/GetById'
	public static KyHanUpdate = 'DmKyHan/Update'

	//Position
	public static PositionInsert = 'CACategorySPBase/CAPositionInsertBase'
	public static PositionGetList = 'CACategorySPBase/CAPositionGetAllOnPageBase'
	public static PositionDelete = 'CACategorySPBase/CAPositionDeleteBase'
	public static PositionGetById = 'CACategorySPBase/CAPositionGetByIDBase'
	public static PositionUpdate = 'CACategorySPBase/CAPositionUpdateBase'

	//DauTu
	public static DauTuInsert = 'DauTu/Insert'
	public static DauTuGetList = 'DauTu/GetList'
	public static DauTuDelete = 'DauTu/Delete'
	public static DauTuGetById = 'DauTu/GetById'
	public static DauTuUpdate = 'DauTu/Update'

	public static DoiTacGetList = 'DauTu/DoiTacGetList'
	public static ListHopDongGetById = 'DauTu/ListHopDongGetById'
	public static ListNhanLaiGetById = 'LaiThuong/ListNhanLaiGetById'
	public static ListNhanThuongGetById = 'LaiThuong/ListNhanLaiGetById'
	public static ListRutLaiGetById = 'LaiThuong/ListNhanLaiGetById'
	public static ListRutThuongGetById = 'LaiThuong/ListNhanLaiGetById'
	public static HopDongGetAll = 'DauTu/GetAllHopDong'

	public static UserGetAll = 'DauTu/GetAllUser'
	public static DauTuInsertAdmin = 'DauTu/InsertAdmin'
	public static DauTuGetListAdmin = 'DauTu/GetListAdmin'
	public static DauTuGetByIdAdmin = 'DauTu/GetByIdAdmin'
	public static DauTuUpdateAdmin = 'DauTu/UpdateAdmin'
	public static DauTuDeleteAdmin = 'DauTu/DeleteAdmin'
	public static DauTuUpdateStatusAdmin = 'DauTu/UpdateStatusAdmin'
	public static DauTuGetDanhSachTrathuongById = 'DauTu/GetDanhSachTrathuongById'
	public static DauTuGetDanhSachDaTrathuongById = 'DauTu/GetDanhSachDaTrathuongById'
	public static DauTuTraThuong = 'DauTu/TraThuong'
	public static DauTuGetBankById = 'DauTu/GetBankById'
	// lãi thưởng quản lý
	public static LaiThuongInsertAdmin = 'LaiThuong/InsertAdmin'
	public static LaiThuongGetListAdmin = 'LaiThuong/GetListAdmin'
	public static LaiThuongGetByIdAdmin = 'LaiThuong/GetByIdAdmin'
	public static LaiThuongUpdateAdmin = 'LaiThuong/UpdateAdmin'
	public static LaiThuongDeleteAdmin = 'LaiThuong/DeleteAdmin'
	public static LaiThuongUpdateStatusAdmin = 'LaiThuong/UpdateStatusAdmin'

	//DauTu
	public static CreateRutLai = 'LaiThuong/Insert'
	public static LaiThuongGetSoTien = 'LaiThuong/GetSoTien'
	public static LaiThuongGetListByUser = 'LaiThuong/GetListById'
	public static DashboardGetSoTien = 'LaiThuong/DashboardGetSoTien'
	public static DashboardGetThongTinQuanLy = 'LaiThuong/DashboardGetThongTinQuanLy'

	// Ví hệ thống
	public static ViHeThongGetList = 'ViHeThong/GetList'
	public static ViHeThongGetById = 'ViHeThong/GetById'
	public static ViHeThongUpdate = 'ViHeThong/Update'
	public static ViHeThongUserGetAll = 'ViHeThong/GetAllUser'
	public static ViHeThongGetSoDuById = 'ViHeThong/GetSoDuById'

	// Ví người dùng
	public static ViNguoiDungGetList = 'ViNguoiDung/GetList'
	public static ListTaiKhoanGetById = 'ViNguoiDung/ListTaiKhoanGetById'
	public static ViNguoiDungGetListGiaoDich = 'ViNguoiDung/GetListGiaoDich'
	public static ViNguoiDungGetListGiaoDichHtc = 'ViNguoiDung/GetListGiaoDichHtc'
	public static ViNguoiDungChangeStatusGiaoDich = 'ViNguoiDung/ChangeStatus'
	public static ViNguoiDungGetPayOutViMo = 'ViNguoiDung/GetPayOutViMo'
	public static ViNguoiDungChangeStatusViMo = 'ViNguoiDung/ChangeStatusViMo'
	public static ViNguoiDungGetDanhSachTrathuongById = 'ViNguoiDung/GetDanhSachTrathuongById'
	public static ViNguoiDungTraThuong = 'ViNguoiDung/TraThuong'
	public static ViNguoiDungLichSuTraThuongById = 'ViNguoiDung/LichSuTraThuongById'
	public static ViNguoiDungGetListThietBi = 'ViNguoiDung/GetListThietBi'
	public static ViNguoiDungGetListBank = 'ViNguoiDung/GetListBank'
	public static ViNguoiDungUpdateStatusBankByUser = 'ViNguoiDung/UpdateStatusBankByUser'
	public static ViNguoiDungDeleteBankByUser = 'Mobile/DeleteBankByUser'
	// tài khoản người dùng
	public static TaiKhoanNguoiDungGetList = 'TaiKhoanNguoiDung/GetList'
	public static TaiKhoanNguoiDungGetListViHeThong = 'TaiKhoanNguoiDung/GetListViHeThong'
	public static TaiKhoanNguoiDungInsert = 'TaiKhoanNguoiDung/Insert'
	public static TaiKhoanNguoiDungUpdate = 'TaiKhoanNguoiDung/Update'
	public static TaiKhoanNguoiDungGetById = 'TaiKhoanNguoiDung/GetById'
	public static TaiKhoanNguoiDungUpdateStatus = 'TaiKhoanNguoiDung/UpdateStatus'
	public static TaiKhoanNguoiDungDelete = 'TaiKhoanNguoiDung/Delete'
	public static TaiKhoanNguoiDungGetHistoryById = 'TaiKhoanNguoiDung/GetHistoryById'



	// user
	public static UserGetPagedList = 'SYUserSPBase/SYUserGetAllOnPageBase'
	public static UserGetById = 'SYUserSPBase/SYUserGetByIDBase'
	public static UserGetByIdUpdate = 'User/UserGetByID'
	public static UserGetByIdView = 'SYUserSPBase/SYUserGetByIDView'
	public static UsersGetDataForCreate = 'User/UsersGetDataForCreate'
	public static UserInsert = 'User/Create' //'SYUserSPBase/SYUserInsertBase'
	public static UserUpdate = 'User/Update' //'SYUserSPBase/SYUserUpdateBase'
	public static UserUpdateStatus = 'User/SYUserUpdateStatus' //'SYUserSPBase/SYUserUpdateBase'
	public static UserResetCountLock = 'User/SYUserResetCountLock' //'SYUserSPBase/SYUserUpdateBase'
	public static UserDelete = 'User/Delete' //'SYUserSPBase/SYUserDeleteBase'GetAvatar
	public static UserGetAvatar = 'User/GetAvatar' //'SYUserSPBase/SYUserDeleteBase'
	public static UserChangePassword = 'User/ChangePassword'
	public static UserGetIsSystem = 'SYUserSPBase/SYUserGetIsSystemBase'
	public static UserGetIsSystemNotInGroupUser = 'SYUserSPBase/SYUserGetIsSystemNotInGroupUser'
	public static UserGetForCreateRole = 'SYUserSPBase/SYUserGetForCreateRole'
	public static UserGetByRoleIdOnPage = 'SYUserSPBase/SYUserGetByRoleIdAllOnPageBase'
	public static DeleteUserRole = 'SYUserSPBase/SYUserRoleMapDeleteBase'
	public static InsertMultiUserRole = 'User/SYUserRoleMapListInsert'
	public static UserGetByRoleId = 'SYUserSPBase/SYUserGetAllByRoleIdBase'
	public static UserGetByGroupUserIdOnPage = 'SYUserSPBase/SYUserGetByGroupUserIdAllOnPageBase'
	public static UserGetByGroupUserId = 'SYUserSPBase/SYUserGetAllByGroupUserIdBase'
	public static InsertMultiUserGroupUser = 'User/SYUserGroupUserListInsert'
	public static DeleteUserGroupUser = 'SYUserSPBase/SYUserGroupUserDeleteBase'
	public static UserCheckExists = 'User/SYUserCheckExists'
	public static UserSystemUpdate = 'User/SYUserSystemUpdate'
	public static UserCheckExistsCode = 'User/SYUserCheckExistedCode'

	public static UserSystemUpdateMore = 'User/SYUserSystemUpdate_More'
	public static UserSystemUpdateHopDong = 'User/SYUserSystemUpdateHopDong'
	public static UserBankUpdate = 'User/DMBankUserUpdate'
	public static UserBankGetByID = 'User/DMBankUserGetByID'

	//SY_Role
	public static RoleGetAll = 'SYSPBase/SYRoleGetAllBase'
	public static RoleGetAllOnPage = 'SYSPBase/SYRoleGetAllOnPageBase'
	public static RoleInsert = 'SYSPBase/SYRoleInsertBase'
	public static RoleUpdate = 'SYSPBase/SYRoleUpdateBase'
	public static RoleUpdateStatus = 'SYSPBase/SYRoleUpdateStatus'
	public static RoleDelete = 'SYSPBase/SYRoleDeleteBase'
	public static RoleGetById = 'SYSPBase/SYRoleGetByID'
	public static RoleInsertPermission = 'SYSPBase/SYPermissionGroupUserInsertByListBase'
	public static RoleGetDataForCreate = 'SYSPBase/SYRoleGetDataForCreate'

	//SY_GroupUser
	public static GroupUserGetAll = 'SYSPBase/SYGroupUserGetAllBase'
	public static GroupUserGetAllOnPage = 'SYSPBase/SYGroupUserGetAllOnPageBase'
	public static GroupUserInsert = 'SYSPBase/SYGroupUserInsertBase'
	public static GroupUserUpdate = 'SYSPBase/SYGroupUserUpdateBase'
	public static GroupUserUpdateStatus = 'SYSPBase/SYGroupUserUpdateStatus'
	public static GroupUserDelete = 'SYSPBase/SYGroupUserDeleteBase'
	public static GroupUserGetById = 'SYSPBase/SYGroupUserGetByID'

	//SY_SystemConfig
	public static SystemConfigGetAllOnPage = 'SYSPBase/SYSystemConfigGetAllOnPage'
	public static SystemConfigGetByID = 'SYSPBase/SYSystemConfigGetByID'
	public static SystemConfigUpdate = 'SYSPBase/SYSystemConfigUpdate'
	public static SystemConfigUpdateStatus = 'SYSPBase/SYSystemConfigUpdateStatus'

	//SY_SystemSetting
	public static SystemSettingGetAllOnPage = 'SYSPBase/SYSystemSettingGetAllOnPage'
	public static SystemSettingGetByID = 'SYSPBase/SYSystemSettingGetByID'
	public static SystemSettingInsert = 'SYSPBase/SYSystemSettingInsert'
	public static SystemSettingUpdate = 'SYSPBase/SYSystemSettingUpdate'
	public static SystemSettingDelete = 'SYSPBase/SYSystemSettingDelete'

	//SY_WebServices
	public static WebServicesGetAllOnPage = 'SYSPBase/SYWebServicesGetAllOnPage'
	public static WebServicesGetByID = 'SYSPBase/SYWebServicesGetByID'
	public static WebServicesInsert = 'SYSPBase/SYWebServicesInsert'
	public static WebServicesUpdate = 'SYSPBase/SYWebServicesUpdate'
	public static WebServicesUpdateStatus = 'SYSPBase/SYWebServicesUpdateStatus'
	public static WebServicesDelete = 'SYSPBase/SYWebServicesDelete'

	//SY_DataNetwork
	public static DataNetworkGetAllOnPage = 'SYSPBase/SYDataNetworkGetAllOnPage'
	public static DataNetworkGetByID = 'SYSPBase/SYDataNetworkGetByID'
	public static DataNetworkInsert = 'SYSPBase/SYDataNetworkInsert'
	public static DataNetworkUpdate = 'SYSPBase/SYDataNetworkUpdate'
	public static DataNetworkDelete = 'SYSPBase/SYDataNetworkDelete'

	//SY_SystemSchedule
	public static SystemScheduleGetAllOnPage = 'SYSPBase/SYSystemScheduleGetAllOnPage'
	public static SystemScheduleGetByID = 'SYSPBase/SYSystemScheduleGetByID'
	public static SystemScheduleInsert = 'SYSPBase/SYSystemScheduleInsert'
	public static SystemScheduleUpdate = 'SYSPBase/SYSystemScheduleUpdate'
	public static SystemScheduleDelete = 'SYSPBase/SYSystemScheduleDelete'

	//NewsType
	public static NewsTypeGetList = 'CACategorySPBase/CANewsTypeGetAllOnPageBase'
	public static NewsTypeGetById = 'CACategorySPBase/CANewsTypeGetByIDBase'
	public static NewsTypeInsert = 'CACategorySPBase/CANewsTypeInsertBase'
	public static NewsTypeUpdate = 'CACategorySPBase/CANewsTypeUpdateBase'
	public static NewsTypeUpdateStatus = 'CACategorySPBase/CANewsTypeUpdateBase'
	public static NewsTypeDelete = 'CACategorySPBase/CANewsTypeDeleteBase'
	//DepartmentGroup
	public static DepartmentGroupGetList = 'CACategorySPBase/CADepartmentGroupGetAllOnPageBase'
	public static DepartmentGroupGetById = 'CACategorySPBase/CADepartmentGroupGetByIDBase'
	public static DepartmentGroupInsert = 'CACategorySPBase/CADepartmentGroupInsertBase'
	public static DepartmentGroupUpdate = 'CACategorySPBase/CADepartmentGroupUpdateBase'
	public static DepartmentGroupUpdateStatus = 'CACategorySPBase/CADepartmentGroupUpdateBase'
	public static DepartmentGroupDelete = 'CACategorySPBase/CADepartmentGroupDeleteBase'
	//Word
	public static WordGetList = 'CACategorySPBase/CAWordGetAllOnPageBase'
	public static WordGetById = 'CACategorySPBase/CAWordGetByIDBase'
	public static WordInsert = 'CACategorySPBase/CAWordInsertBase'
	public static WordUpdate = 'CACategorySPBase/CAWordUpdateBase'
	public static WordUpdateStatus = 'CACategorySPBase/CAWordUpdateBase'
	public static WordDelete = 'CACategorySPBase/CAWordDeleteBase'
	public static WordGetListSuggest = 'CACategorySPBase/CAWordGetListSuggestBase'

	//tin tuc
	public static NewsGetAllOnPage = 'NESPBase/NENewsGetAllOnPageBase'
	public static NewsGetListHomePage = 'NESPBase/NENewsGetListHomePage'
	public static NewsGetById = 'NESPBase/NENewsGetByIDBase'
	public static NewsInsert = 'NESPBase/NENewsInsertBase'
	public static NewsUpdate = 'NESPBase/NENewsUpdateBase'
	public static NewsDelete = 'NESPBase/NENewsDeleteBase'
	public static NewsUploadFile = 'files/upload?folder=News'
	public static NewsRelatesGetAll = 'NESPBase/NERelateGetAllBase'
	public static NewsGetAvatar = 'files/get-news-avatar'
	public static NewsGetAvatars = 'files/get-news-avatar'

	//Department
	public static DepartmentGetList = 'CACategorySPBase/CADepartmentGetAllOnPageBase'
	public static DepartmentGetById = 'CACategorySPBase/CADepartmentGetByIDBase'
	public static DepartmentInsert = 'CACategorySPBase/CADepartmentInsertBase'
	public static DepartmentUpdate = 'CACategorySPBase/CADepartmentUpdateBase'
	public static DepartmentUpdateStatus = 'CACategorySPBase/CADepartmentUpdateBase'
	public static DepartmentDelete = 'CACategorySPBase/CADepartmentDeleteBase'
	public static SYEmailGetData = 'CACategorySPBase/SYEmailGetData'
	public static SYEmailUpdateData = 'CACategorySPBase/SYEmailUpdateData'
	public static SYSmsGetData = 'CACategorySPBase/SYSmsGetData'
	public static SYSmsUpdateData = 'CACategorySPBase/SYSmsUpdateData'

	public static RemindInsert = 'RMRemind/RemindInsert'
	public static RemindGetList = 'RMRemind/RemindGetList'

	//RequestRecommendation
	public static RecommendationRequestGetListProcess = 'MRSPBase/MRRecommendationKNCTGetAllWithProcessBase'
	public static RecommendationRequestGetDataForCreate = 'CACategorySPBase/CAFieldKNCTGetDropdownBase'

	//api dia danh
	public static ProvinceGetAll = 'CASPBase/CAProvinceGetAllBase'
	public static DistrictGetAll = 'CASPBase/CADistrictGetAllBase'
	public static VillageGetAll = 'CASPBase/CAVillageGetAllBase'

	//register
	public static RegisterIndividual = 'User/InvididualRegister'
	public static RegisterOrganization = 'User/OrganizationRegister'

	public static RecommendationRequestGetByIdView = 'MRSPBase/MRRecommendationKNCTGetByIdBase'
	public static RecommendationRequestGetFile = 'MRSPBase/MRRecommendationKNCTFilesGetByRecommendationIdBase'

	//Administrative Formalities
	public static AdministrativeFormalitiesGetDropdown = 'AdministrativeFormalities/AdministrativeFormalitiesGetDropdown'
	public static AdministrativeFormalitiesGetList = 'DAMSPBase/DAMAdministrationGetListBase'
	public static AdministrativeFormalitiesGetById = 'AdministrationFormalities/AdministrationFormalitiesGetByID'
	public static AdministrativeFormalitiesGetByIdView = 'AdministrativeFormalities/RecommendationGetByIdView'
	public static AdministrativeFormalitiesInsert = 'AdministrationFormalities/AdministrationFormalitiesInsert'
	public static AdministrativeFormalitiesUpdate = 'AdministrationFormalities/AdministrationFormalitiesUpdate'
	public static AdministrativeFormalitiesDelete = 'DAMSPBase/DAMAdministrationDeleteBase'

	//VehicleType
	public static VehicleTypeInsert = 'CACategorySPBase/CAVehicleTypeInsertBase'
	public static VehicleTypeGetList = 'CACategorySPBase/CAVehicleTypeGetAllOnPageBase'
	public static VehicleTypeDelete = 'CACategorySPBase/CAVehicleTypeDeleteBase'
	public static VehicleTypeGetById = 'CACategorySPBase/CAVehicleTypeGetByIDBase'
	public static vehicleTypeUpdate = 'CACategorySPBase/CAVehicleTypeUpdateBase'

	//DeviceType
	public static DeviceTypeInsert = 'CACategorySPBase/CADeviceTypeInsertBase'
	public static DeviceTypeGetList = 'CACategorySPBase/CADeviceTypeGetAllOnPageBase'
	public static DeviceTypeDelete = 'CACategorySPBase/CADeviceTypeDeleteBase'
	public static DeviceTypeGetById = 'CACategorySPBase/CADeviceTypeGetByIDBase'
	public static DeviceTypeUpdate = 'CACategorySPBase/CADeviceTypeUpdateBase'

	//WarningType
	public static WarningTypeInsert = 'CACategorySPBase/CAWarningTypeInsertBase'
	public static WarningTypeGetList = 'CACategorySPBase/CAWarningTypeGetAllOnPageBase'
	public static WarningTypeDelete = 'CACategorySPBase/CAWarningTypeDeleteBase'
	public static WarningTypeGetById = 'CACategorySPBase/CAWarningTypeGetByIDBase'
	public static WarningTypeUpdate = 'CACategorySPBase/CAWarningTypeUpdateBase'

	//SupportType
	public static SupportTypeInsert = 'CACategorySPBase/CASupportTypeInsertBase'
	public static SupportTypeGetList = 'CACategorySPBase/CASupportTypeGetAllOnPageBase'
	public static SupportTypeDelete = 'CACategorySPBase/CASupportTypeDeleteBase'
	public static SupportTypeGetById = 'CACategorySPBase/CASupportTypeGetByIDBase'
	public static SupportTypeUpdate = 'CACategorySPBase/CASupportTypeUpdateBase'

	//Reason
	public static ReasonInsert = 'CACategorySPBase/CAReasonInsertBase'
	public static ReasonGetList = 'CACategorySPBase/CAReasonGetAllOnPageBase'
	public static ReasonDelete = 'CACategorySPBase/CAReasonDeleteBase'
	public static ReasonGetById = 'CACategorySPBase/CAReasonGetByIDBase'
	public static ReasonUpdate = 'CACategorySPBase/CAReasonUpdateBase'

	//TypeWater
	public static TypeWaterInsert = 'CACategorySPBase/CATypeWaterInsertBase'
	public static TypeWaterGetList = 'CACategorySPBase/CATypeWaterGetAllOnPageBase'
	public static TypeWaterDelete = 'CACategorySPBase/CATypeWaterDeleteBase'
	public static TypeWaterGetById = 'CACategorySPBase/CATypeWaterGetByIDBase'
	public static TypeWaterUpdate = 'CACategorySPBase/CATypeWaterUpdateBase'

	//TypeCustomer
	public static TypeCustomerInsert = 'CACategorySPBase/CATypeCustomerInsertBase'
	public static TypeCustomerGetList = 'CACategorySPBase/CATypeCustomerGetAllOnPageBase'
	public static TypeCustomerDelete = 'CACategorySPBase/CATypeCustomerDeleteBase'
	public static TypeCustomerGetById = 'CACategorySPBase/CATypeCustomerGetByIDBase'
	public static TypeCustomerUpdate = 'CACategorySPBase/CATypeCustomerUpdateBase'

	//ForceSupport
	public static ForceSupportInsert = 'CACategorySPBase/SPForceSupportInsertBase'
	public static ForceSupportGetAllOnPage = 'CACategorySPBase/SPForceSupportGetAllOnPageBase'
	public static ForceSupportUpdate = 'CACategorySPBase/SPForceSupportUpdateBase'
	public static ForceSupportDelete = 'CACategorySPBase/SPForceSupportDeleteBase'
	public static ForceSupportGetById = 'CACategorySPBase/SPForceSupportGetByIDBase'

	//Electricity
	public static GetAllWard = 'CACategorySPBase/CAAdministrativeUnitsByDistrictIdBase'
	public static GetAllDistrict = 'CACategorySPBase/CAAdministrativeUnitsByProvinceIdBase'
	public static ElectricityInsert = 'CACategorySPBase/SPElectricityInsertBase'
	public static GetAllOnPageElectricity = 'CACategorySPBase/SPElectricityGetAllOnPageBase'
	public static ElectricityUpdate = 'CACategorySPBase/SPElectricityUpdateBase'
	public static ElectricityDelete = 'CACategorySPBase/SPElectricityDeleteBase'
	public static ElectricityGetById = 'CACategorySPBase/SPElectricityGetByIDBase'

	//WaterSupply
	public static WaterSupplyInsert = 'CACategorySPBase/SPWaterSupplyInsertBase'
	public static GetAllOnPageWaterSupply = 'CACategorySPBase/SPWaterSupplyGetAllOnPageBase'
	public static WaterSupplyUpdate = 'CACategorySPBase/SPWaterSupplyUpdateBase'
	public static WaterSupplyDelete = 'CACategorySPBase/SPWaterSupplyDeleteBase'
	public static WaterSupplyGetById = 'CACategorySPBase/SPWaterSupplyGetByIDBase'

	//Medical
	public static MedicalInsert = 'CACategorySPBase/SPMedicalInsertBase'
	public static GetAllOnPageMedical = 'CACategorySPBase/SPMedicalGetAllOnPageBase'
	public static MedicalUpdate = 'CACategorySPBase/SPMedicalUpdateBase'
	public static MedicalDelete = 'CACategorySPBase/SPMedicalDeleteBase'
	public static MedicalGetById = 'CACategorySPBase/SPMedicalGetByIDBase'

	//Traffic
	public static TrafficInsert = 'CACategorySPBase/SPTrafficInsertBase'
	public static GetAllOnPageTraffic = 'CACategorySPBase/SPTrafficGetAllOnPageBase'
	public static TrafficUpdate = 'CACategorySPBase/SPTrafficUpdateBase'
	public static TrafficDelete = 'CACategorySPBase/SPTrafficDeleteBase'
	public static TrafficGetById = 'CACategorySPBase/SPTrafficGetByIDBase'

	//FireHydrant
	public static GetAllManagementUnit = 'CACategorySPBase/CAFireHydrantGetAllManagementUnitBase'
	public static FireHydrantInsert = 'CACategorySPBase/CAFireHydrantInsertBase'
	public static GetAllOnPageFireHydrant = 'CACategorySPBase/CAFireHydrantGetAllOnPageBase'
	public static GetAllOnPageFireHydrantForSolider = 'CACategorySPBase/CAFireHydrantGetAllOnPageForSolider'
	public static FireHydrantUpdate = 'CACategorySPBase/CAFireHydrantUpdateBase'
	public static FireHydrantDelete = 'CACategorySPBase/CAFireHydrantDeleteBase'
	public static FireHydrantGetById = 'CACategorySPBase/CAFireHydrantGetByIDBase'
	public static GetWaterTypeDrop = 'CACategorySPBase/CAWaterTypeDropBase'

	//WaterSource
	public static WaterSourceInsert = 'CACategorySPBase/CAWaterSourceInsertBase'
	public static GetAllOnPageWaterSource = 'CACategorySPBase/CAWaterSourceGetAllOnPageBase'
	public static GetAllOnPageWaterSourceForSolider = 'CACategorySPBase/CAWaterSourceGetAllOnPageForSolider'
	public static WaterSourceUpdate = 'CACategorySPBase/CAWaterSourceUpdateBase'
	public static WaterSourceDelete = 'CACategorySPBase/CAWaterSourceDeleteBase'

	//DocumentType
	public static DocumentTypeInsert = 'CACategorySPBase/CADocumentTypeInsertBase'
	public static DocumentTypeGetList = 'CACategorySPBase/CADocumentTypeGetAllOnPageBase'
	public static DocumentTypeDelete = 'CACategorySPBase/CADocumentTypeDeleteBase'
	public static DocumentTypeGetById = 'CACategorySPBase/CADocumentTypeGetByIDBase'
	public static DocumentTypeUpdate = 'CACategorySPBase/CADocumentTypeUpdateBase'

	//SignalType
	public static SignalTypeInsert = 'CACategorySPBase/CASignalTypeInsertBase'
	public static SignalTypeGetList = 'CACategorySPBase/CASignalTypeGetAllOnPageBase'
	public static SignalTypeDelete = 'CACategorySPBase/CASignalTypeDeleteBase'
	public static SignalTypeGetById = 'CACategorySPBase/CASignalTypeGetByIDBase'
	public static SignalTypeUpdate = 'CACategorySPBase/CASignalTypeUpdateBase'

	//Management Unit
	public static ManagementUnitInsert = 'CACategorySPBase/CAManagementUnitInsertBase'
	public static ManagementUnitGetList = 'CACategorySPBase/CAManagementUnitGetAllOnPageBase'
	public static ManagementUnitDelete = 'CACategorySPBase/CAManagementUnitDeleteBase'
	public static ManagementUnitGetById = 'CACategorySPBase/CAManagementUnitGetByIDBase'
	public static ManagementUnitUpdate = 'CACategorySPBase/CAManagementUnitUpdateBase'

	//Damage Level
	public static DamageLevelInsert = 'CACategorySPBase/CADamageLevelInsertBase'
	public static DamageLevelGetList = 'CACategorySPBase/CADamageLevelGetAllOnPageBase'
	public static DamageLevelDelete = 'CACategorySPBase/CADamageLevelDeleteBase'
	public static DamageLevelGetById = 'CACategorySPBase/CADamageLevelGetByIDBase'
	public static DamageLevelUpdate = 'CACategorySPBase/CADamageLevelUpdateBase'

	//Object
	public static ObjectInsert = 'CACategorySPBase/CAObjectInsertBase'
	public static ObjectGetList = 'CACategorySPBase/CAObjectGetAllOnPageBase'
	public static ObjectDelete = 'CACategorySPBase/CAObjectDeleteBase'
	public static ObjectGetById = 'CACategorySPBase/CAObjectGetByIDBase'
	public static ObjectUpdate = 'CACategorySPBase/CAObjectUpdateBase'

	//Team
	public static TeamInsert = 'CACategorySPBase/CATeamInsertBase'
	public static TeamGetList = 'CACategorySPBase/CATeamGetAllOnPageBase'
	public static TeamDelete = 'CACategorySPBase/CATeamDeleteBase'
	public static TeamGetById = 'CACategorySPBase/CATeamGetByIDBase'
	public static TeamUpdate = 'CACategorySPBase/CATeamUpdateBase'
	public static TeamUserDropdown = 'SYSolider/SYSoliderDropdownBase'
	public static GetAllMemberTeamDropdown = 'CACategorySPBase/CAMemberTeamDropdownBase'
	public static MemberTeamDelete = 'CACategorySPBase/CAMemberTeamDeleteBase'
	public static MemberTeamInsert = 'CACategorySPBase/CAMemberTeamInsertBase'
	public static TeamUpdateStatus = 'CACategorySPBase/CATeamUpdateStatusBase'

	//Device
	public static GetAllDropDeviceType = 'CACategorySPBase/CADeviceTypeGetDropdownBase'
	public static GetAllDropBusinessCustomer = 'CACategorySPBase/BCBusinessCustomerGetDropdownBase'
	public static DeviceInsert = 'CACategorySPBase/CADeviceInsertBase'
	public static DeviceGetList = 'CACategorySPBase/CADeviceGetAllOnPageBase'
	public static DeviceDelete = 'CACategorySPBase/CADeviceDeleteBase'
	public static DeviceGetById = 'CACategorySPBase/CADeviceGetByIDBase'
	public static DeviceUpdate = 'CACategorySPBase/CADeviceUpdateBase'
	public static DeviceLogGetList = 'CACategorySPBase/CADeviceLogGetAllOnPageBase'

	//TeamMember
	public static TeamMemberInsert = 'CACategorySPBase/CATeamMemberInsertBase'
	public static GetAllOnPageTeamMember = 'CACategorySPBase/CATeamMemberGetAllOnPageBase'
	public static TeamMemberUpdate = 'CACategorySPBase/CATeamMemberUpdateBase'
	public static TeamMemberDelete = 'CACategorySPBase/CATeamMemberDeleteBase'

	//Vehicle
	public static VehicleInsert = 'CACategorySPBase/CAVehicleInsertBase'
	public static VehicleGetList = 'CACategorySPBase/CAVehicleGetAllOnPageBase'
	public static VehicleGetListForSolider = 'CACategorySPBase/CAVehicleGetAllOnPageForSolider'
	public static VehicleDelete = 'CACategorySPBase/CAVehicleDeleteBase'
	public static VehicleGetById = 'CACategorySPBase/CAVehicleGetByIDBase'
	public static VehicleUpdate = 'CACategorySPBase/CAVehicleUpdateBase'
	public static VehicleTypeGetDropdown = 'CACategorySPBase/CAVehicleTypeGetDropdownBase'
	public static ManagementUnitGetDropdownBase = 'CACategorySPBase/CAManagementUnitGetDropdownBase'
	public static ManagementUnitVehicleGetDropdownBase = 'CACategorySPBase/CAManagementUnitVehicleGetDropdownBase'

	//Citizen
	public static CitizenGetList = 'CICitizen/CICitizenGetAllOnPageBase'

	//SystemLog
	public static GetListAllPageSysLog = 'SYLogSPBase/SYSystemLogGetAllOnPageBase'
	public static SYLOGGetByIDBase = 'SYLogSPBase/SYLOGGetByIDBase'
	public static SYSystemLogDeleteBase = 'SYLogSPBase/SYSystemLogDeleteBase'
	public static SYSystemLogGetAllOnPageBase = 'SYLogSPBase/SYSystemLogGetAllOnPageBase'

	//Citizen
	public static HISSYSoliderGetByID = 'SYSolider/HISSYSoliderGetByID'
	public static SYSoliderGetDataForCreate = 'SYSolider/SYSoliderGetDataForCreate'
	public static SYSoliderGetAllOnPage = 'SYSolider/SYSoliderGetAllOnPage'
	public static SYSoliderGetByID = 'SYSolider/SYSoliderGetByID'
	public static SYSoliderGetByIDView = 'SYSolider/SYSoliderGetByIDView'
	public static SYSoliderInsert = 'SYSolider/SYSoliderInsert'
	public static SYSoliderUpdate = 'SYSolider/SYSoliderUpdate'
	public static SYSoliderUpdateStatus = 'SYSolider/SYSoliderUpdateStatus'
	public static SYSoliderDelete = 'SYSolider/SYSoliderDelete'
	public static SYSoliderUpdateApproved = 'SYSolider/SYSoliderUpdateApproved'
	//Region
	public static RegionGetPagedList = 'CACategorySPBase/CAUnitGetAllOnPageBase'
	public static RegionGetAll = 'CACategorySPBase/CAAdministrativeUnitGetAllBase'
	public static RegionGetById = 'CACategorySPBase/CAAdministrativeUnitGetByIdBase'
	public static RegionInsert = 'CACategorySPBase/CAAdministrativeUnitInsertBase'
	public static RegionUpdate = 'CACategorySPBase/CAAdministrativeUnitUpdateBase'
	public static RegionDeleteList = 'CACategorySPBase/CAUnitDeleteListBase'
	public static RegionDelete = 'CACategorySPBase/CAAdministrativeUnitDeleteBase'
	public static GetAllProvince = 'CACategorySPBase/CAAdministrativeUnitsGetAllProvinceBase'
	public static GetDistrict = 'CACategorySPBase/CAAdministrativeUnitsByProvinceIdBase'

	//Area
	public static AreaInsert = 'CACategorySPBase/CAAreaInsertBase'
	public static AreaGetList = 'CACategorySPBase/CAAreaGetAllOnPageBase'
	public static AreaDelete = 'CACategorySPBase/CAAreaDeleteBase'
	public static AreaGetById = 'CACategorySPBase/CAAreaGetByIDBase'
	public static AreaUpdate = 'CACategorySPBase/CAAreaUpdateBase'

	//SHShift
	public static ShiftInsert = 'SHShift/SHShiftInsert'
	public static ShiftGetList = 'SHShift/SHShiftGetAllOnPage'
	public static ShiftDelete = 'SHShift/SHShiftDelete'
	public static ShiftGetById = 'SHShift/SHShiftGetByID'
	public static ShiftUpdate = 'SHShift/SHShiftUpdate'
	public static UserGetDropdown = 'SHShift/UserGetDropdown'
	public static UserUnitGetDropdown = 'SHShift/UserUnitGetDropdown'
	//CaseInformation
	public static CaseInfo_GetCommonDropdownList = 'FICaseSPBase/CaseInfo_GetCommonDropdownList'
	public static GetAllOnPageCaseInfo = 'FICaseSPBase/FICaseInformationGetAllOnPageBase'
	public static GetListUnitCaseInfo = 'FICaseSPBase/FICaseInfoGetListUnitBase'
	public static CaseInfoGetById = 'FICaseSPBase/FICaseInfoGetByIdBase'
	public static CaseInfoGetAllInfo = 'FICaseSPBase/FICaseGetAllInfoBase'
	public static CaseInfoGetFileByFireId = 'FICaseSPBase/FICaseGetFileByFireIdBase'
	public static CaseInfoForward = 'FICaseSPBase/FICaseGetForwardBase'
	public static CaseInfoHistory = 'FICaseSPBase/FICaseGetHistoryBase'
	public static CaseInfoGetAllVehicle = 'FICaseSPBase/FICaseGetAllVehicleBase'
	public static CaseGetVehicleByListUnit = 'FICaseSPBase/FICaseGetVehicleByListUnit'
	public static CaseInfoGetAllTeam = 'FICaseSPBase/FICaseGetAllTeamBase'
	public static CaseInfoGetAllForceSupport = 'FICaseSPBase/FICaseGetAllForceSupportBase'
	public static CaseInfoGetAllUnit = 'FICaseSPBase/FICaseGetAllUnitBase'
	public static CaseInfoGetAllReason = 'FICaseSPBase/FICaseGetAllReasonBase'
	public static CaseInfoGetAllDamage = 'FICaseSPBase/FICaseGetAllDamageBase'
	public static CaseInfoGetComeinandInfoByCaseId = 'FICaseSPBase/FICaseGetComeinandInfoByCaseIdBase'
	public static CaseInfoComeinandInsert = 'FICaseSPBase/FICaseComeinandInsertBase'
	public static CaseInfoComeinandUpdate = 'FICaseSPBase/FICaseComeinandUpdateBase'
	public static CaseInfoResultInsert = 'FICaseSPBase/FICaseResultInsertBase'
	public static CaseInfoResultUpload = 'FICaseSPBase/FICaseResultUploadBase'
	public static CaseInfoResultRemoveFile = 'FICaseSPBase/FICaseResultRemoveFileBase'
	public static CaseInfoGetResultInfoByCaseId = 'FICaseSPBase/FICaseGetResultInfoByCaseIdBase'

	public static CaseInfoGetListWaitProcess = 'FICaseSPBase/FICaseInfoGetListWaitProcessBase'
	public static CaseInfoGetListWaitResolve = 'FICaseSPBase/FICaseInfoGetListWaitResolveBase'
	public static CaseInfoGetListResolving = 'FICaseSPBase/FICaseInfoGetListResolvingBase'
	public static CaseInfoGetListResolved = 'FICaseSPBase/FICaseInfoGetListResolvedBase'
	public static CaseInfoGetAllUser = 'FICaseSPBase/FICaseGetAllUserBase'
	public static CaseInfoInsert = 'FICaseSPBase/FICaseInsertBase'
	public static CaseInfoHistoryByCaseId = 'FICaseSPBase/FICaseGetHistoryByCaseIdBase'
	public static CaseInfoGetFileImage = 'FICaseSPBase/GetFileImage'
	public static CaseInfoDownloadFile = 'FICaseSPBase/DownloadFile'
	public static CaseInfoDownloadVideo = 'FICaseSPBase/DownloadVideo'

	//Document
	public static GetAllDropDocType = 'DODocumentSPBase/DODocGetAllDocTypeDropBase'
	public static DocInsert = 'DODocumentSPBase/DODocInsertBase'
	public static DocGetList = 'DODocumentSPBase/DODocGetAllOnPageBase'
	public static DocDelete = 'DODocumentSPBase/DODocDeleteBase'
	public static DocGetById = 'DODocumentSPBase/DODocGetByIDBase'
	public static DocUpdate = 'DODocumentSPBase/DODocUpdateBase'
	public static DocUploadFile = 'DODocumentSPBase/DODocUploadFileBase'
	public static DocGetAllFileByDocId = 'DODocumentSPBase/DODocGetFilesByDocIdBase'
	public static DocRemoveFile = 'DODocumentSPBase/DODocRemoveFileBase'
	public static DocHistoryByDocId = 'DODocumentSPBase/DODocGetHistoryByDocIdBase'
	public static DocUpdateStatus = 'DODocumentSPBase/DODocUpdateStatusBase'

	//FireInformation
	public static GetCommonDropdownList = 'FIFireInfomation/GetCommonDropdownList'
	public static FireInfomationGetList = 'FIFireInfomation/GetAllOnPage'
	public static VuViecGetDropdown = 'FIFireInfomation/VuViecGetDropdown'
	public static GetListThongTinDamChay = 'FIFireInfomation/GetListThongTinDamChay'
	public static SYUnitGetDropdownBase = 'SYSPBase/SYUnitGetDropdownBase'
	public static FireInfomationGetFileImage = 'FIFireInfomation/GetFileImage'
	public static CreateVuviec = 'FIFireInfomation/CreateVuviec'
	public static CreateFI = 'FIFireInfomation/CreateFI'
	public static UpdateFI = 'FIFireInfomation/UpdateFI'
	public static FireInfomationUpdateStatus = 'FIFireInfomation/FireInfomationUpdateStatus'
	public static FireInfomationUpdateStatusMultiple = 'FIFireInfomation/FireInfomationUpdateStatusMultiple'
	public static FireInfomationUpdateToVuViec = 'FIFireInfomation/FireInfomationUpdateToVuViec'
	public static FireInfomationGetListTuChoi = 'FIFireInfomation/FireInfomationGetListTuChoi'
	public static FireInfomationDelete = 'FIFireInfomation/FIFireInfomationDelete'
	public static FireInfomationHistory = 'FIFireInfomation/GetHistory'
	public static FIFireGetDataByDevice = 'FIFireInfomation/FIFireGetDataByDevice'

	//customer
	public static CustomerRegister = 'BCCategorySPBase/BCBusinessCustomerRegister'
	public static CustomerInsert = 'BCCategorySPBase/BCBusinessCustomerInsertBase'
	public static CustomerGetDataForCreate = 'BCCategorySPBase/GetDataForCreate'
	public static CustomerGetDataForCreateAuthor = 'BCCategorySPBase/GetDataForCreateAuthor'
	public static GetDistrictDropdown = 'CACategorySPBase/CADistrictGetDropdownBase'
	public static GetWardByDistrictId = 'CACategorySPBase/CAWardGetByDistrictIdBase'
	public static GetAreaDropdown = 'CACategorySPBase/CAAreaGetDropdownBase'
	public static GetFieldDropdown = 'CACategorySPBase/CAFieldGetDropdownBase'
	public static CheckMailCustomer = 'BCCategorySPBase/BCBusinessCustomerCheckEmailBase'
	public static CheckPhoneCustomer = 'BCCategorySPBase/BCBusinessCustomerCheckPhoneBase'
	public static CustomerUpdate = 'BCCategorySPBase/BCBusinessCustomerUpdateStatusBase'
	public static GetAllOnPageCustomer = 'BCCategorySPBase/BCBusinessCustomerGetAllOnPageBase'
	public static GetAllOnPageCustomerForSolider = 'BCCategorySPBase/BCBusinessCustomerGetAllOnPageForSolider'
	public static CustomerGetById = 'BCCategorySPBase/BCBusinessCustomerGetByIDBase'
	public static WaterSourceInsideGetById = 'BCCategorySPBase/BCWaterSourceInsideGetByIDBase'
	public static WaterSourceOutsideGetById = 'BCCategorySPBase/BCWaterSourceOutsideGetByIDBase'
	public static FilesGetById = 'BCCategorySPBase/BCFilesGetByIDBase'
	public static CustomerUpdateStatus = 'BCCategorySPBase/BCBusinessCustomerUpdateStatusBase'
	public static CustomerDelete = 'BCCategorySPBase/BCBusinessCustomerDeleteBase'
	public static HISBusinessCustomerGetByID = 'BCCategorySPBase/HISBusinessCustomerGetByIDBase'
	public static GetAllOnPageCustomerApprove = 'BCCategorySPBase/BCBusinessCustomerGetApproveOnPageBase'
	public static CustomerUpdateApprove = 'BCCategorySPBase/BCBusinessCustomerUpdateApproveBase'
	public static BHPCCCGetById = 'BCCategorySPBase/BHPCCCGetByIDBase'

	//SY_WebServices
	public static ReportCustomerGetAllOnPage = 'BCCategorySPBase/BCReportGetAllOnPage'
	public static ReportCustomerGetByID = 'BCCategorySPBase/BCReportGetByIdBase'
	public static ReportCustomerInsert = 'BCCategorySPBase/BCReportInsertBase'
	public static ReportCustomerUpdate = 'BCCategorySPBase/BCReportUpdateBase'
	public static ReportCustomerDelete = 'BCCategorySPBase/BCReportDeleteBase'
	public static BCReportGetDataForCreate = 'BCCategorySPBase/BCReportGetDataForCreate'
	public static BCReportUserGetDataForReportCustomer = 'BCCategorySPBase/UserGetDataForReportCustomer'

	//TestSchedule
	public static GetBusinessDropdown = 'TETestscheduleSPBase/TSGetBusinessDropdownBase'
	public static GetTestscheduleMemberAll = 'TETestscheduleSPBase/TSGetTestscheduleMemberAllBase'
	public static GetMemberByDate = 'TETestscheduleSPBase/TSGetMemberByDate'
	public static TestscheduleInsert = 'TETestscheduleSPBase/TETestscheduleInsertBase'
	public static GetAllOnPageTestschedule = 'TETestscheduleSPBase/TETestscheduleGetAllOnPageBase'
	public static DeleteTestschedule = 'TETestscheduleSPBase/TETestscheduleDeleteBase'
	public static GetTestScheduleById = 'TETestscheduleSPBase/TETestScheduleByIdBase'
	public static GetTestScheduleCustomerById = 'TETestscheduleSPBase/TETestScheduleCustomerByIdBase'
	public static GetTestScheduleFilesById = 'TETestscheduleSPBase/TETestScheduleFilesByIdBase'
	public static GetTestScheduleMemberById = 'TETestscheduleSPBase/TETestScheduleMemberByIdBase'
	public static TestscheduleUpdate = 'TETestscheduleSPBase/TETestscheduleUpdateBase'
	public static TestscheduleUpdateStatus = 'TETestscheduleSPBase/TETestscheduleUpdateStatusBase'
	public static TestscheduleUpdateIsShow = 'TETestscheduleSPBase/TETestscheduleUpdateIsShowBase'
	public static TestscheduleFinish = 'TETestscheduleSPBase/TestscheduleFinishBase'
	public static HISTestScheduleGetByID = 'TETestscheduleSPBase/HISTestScheduleGetByIDBase'
	public static GetTestScheduleOfferById = 'TETestscheduleSPBase/TETestScheduleOfferByIdBase'
	public static GetListUserAll = 'TETestscheduleSPBase/TEGetListUserAllBase'
	public static Checkmember = 'TETestscheduleSPBase/Checkmember'
	public static GetBusinessDropdownAll = 'TETestscheduleSPBase/TSGetBusinessDropdownAllBase'

	//SY_Function
	public static FunctionGetAllOnPage = 'SYSPBase/SYFunctionGetAllOnPage'
	public static FunctionGetByID = 'SYSPBase/SYFunctionGetByID'
	public static FunctionInsert = 'SYSPBase/SYFunctionInsert'
	public static FunctionUpdate = 'SYSPBase/SYFunctionUpdate'
	public static FunctionDelete = 'SYSPBase/SYFunctionDelete'

	//Report
	public static REComeinand = 'REReportSPBase/REComeinandGetAllOnPageBase'
	public static RECaseReason = 'REReportSPBase/RECaseGetAllOnPageBase'
	public static RECaseDamage = 'REReportSPBase/RECaseGetAllOnPageBase'
	public static REFireByObject = 'REReportSPBase/REFireByObject'
	public static RECaseTime = 'REReportSPBase/RECaseGetAllOnPageBase'
	public static RECaseWork = 'REReportSPBase/RECaseGetAllOnPageBase'
	public static RECaseShift = 'REReportSPBase/RECaseGetAllOnPageBase'
	public static RECaseCareer = 'REReportSPBase/RECaseCareer'
	public static RECaseArea = 'REReportSPBase/RECaseArea'
	public static REForce = 'REReportSPBase/REForceBase'
	public static REBussinessCustomer = 'REReportSPBase/REBussinessCustomer'
	public static RETestSchedule = 'REReportSPBase/RETestSchedule'

	public static WaterStatisticsReport = 'StatiticCaseInfomation/WaterStatisticsReport'
	public static VehicleStatisticsReport = 'StatiticCaseInfomation/VehicleStatisticsReport'

	//Statistic
	public static STCaseWork = 'STStatisticSPBase/STCaseWorkBase'
	public static STCaseFireTime = 'StatiticCaseInfomation/CaseInfomationByFireTime'
	public static STCaseDameArea = 'StatiticCaseInfomation/CaseInfomationByDameArea'
	public static STCaseReason = 'StatiticCaseInfomation/CaseInfomationByReason'
	public static STCaseObject = 'StatiticCaseInfomation/CaseInfomationByObject'
	public static STCaseCareer = 'StatiticCaseInfomation/CaseInfomationByCareer'

	//Tactic
	public static CATeamGetDropDown = 'CACategorySPBase/CATeamGetDropDownBase'
	public static TacticInsert = 'BCCategorySPBase/BCTacticInsertBase'
	public static GetTacticTeamById = 'BCCategorySPBase/BCTacticTeamByIdBase'
	public static GetTacticById = 'BCCategorySPBase/BCTacticByIdBase'
	public static GetTacticRouterById = 'BCCategorySPBase/BCTacticRouterByIdBase'
	public static GetTacticSkillById = 'BCCategorySPBase/BCTacticSkillByIdBase'
	public static GetTacticForceById = 'BCCategorySPBase/BCTacticForceByIdBase'
	public static TacticDelete = 'BCCategorySPBase/BCTacticDeleteBase'
	public static TacticUpdate = 'BCCategorySPBase/BCTacticUpdateBase'

	//ViHeThong_GiaoDich
	public static ViHeThongGiaoDichInsert = 'ViHeThongGiaoDich/Create'
	public static ViHeThongGiaoDichUpdate = 'ViHeThongGiaoDich/Update'
	public static ViHeThongGiaoDichDelete = 'ViHeThongGiaoDich/Delete'
	public static ViHeThongGiaoDichGetAllOnPage = 'ViHeThongGiaoDich/GetAllOnPage'
	public static ViHeThongGiaoDichGetByID = 'ViHeThongGiaoDich/GetByID'
	public static ViHeThongGiaoDichUpdateStatus = 'ViHeThongGiaoDich/UpdateStatus'
	public static ViHeThongGiaoDichGetDataForCreate = 'ViHeThongGiaoDich/GetDataForCreate'
	public static ViHeThongGiaoDichGetDataDiemForCreate = 'ViHeThongGiaoDich/GetDataDiemForCreate'
	public static ViHeThongGiaoDichDeleteFiles = 'ViHeThongGiaoDich/DeleteFiles'
	public static ViHeThongGiaoDichUpdateSoDu = 'ViHeThongGiaoDich/UpdateSoDu'
	public static ViHeThongGiaoDichGetTongPhatHanh = 'ViHeThongGiaoDich/GetTongPhatHanh'
	public static ViHeThongNganHangGetByID = 'ViHeThong/ViNganHangGetByID'
	public static ViNganHangGetAllOnPage = 'ViHeThong/ViNganHangGetAllOnPage'
	public static ViNganHangGetOne = 'ViHeThong/ViNganHangGetOne'
	public static ViNganHangThanhVienGetAllOnPage = 'ViHeThong/ViNganHangThanhVienGetAllOnPage'
	public static ViNganHangThanhVienGetTong = 'ViHeThong/ViNganHangThanhVienGetTong'
	public static ViNganHangPayOutGetAllOnPage = 'ViHeThong/ViNganHangPayOutGetAllOnPage'
	public static ViGiaoDichGetByID = 'ViHeThong/ViGiaoDichGetByID'
	public static ViGiaoDichGetAllOnPage = 'ViHeThong/ViGiaoDichGetAllOnPage'
	public static ViGiaoDichGetOne = 'ViHeThong/ViGiaoDichGetOne'
	public static ViGiaoDichDiemGetOne = 'ViHeThong/ViGiaoDichDiemGetOne'
	public static ViGiaoDichChuyenKhoan = 'ViHeThong/ViGiaoDichChuyenKhoan'
	public static ViNguoiDungGetThongTinByTaiKhoan = 'ViHeThong/ViNguoiDungGetThongTinByTaiKhoan'
	public static ViGiaoDichChuyenKhoanGetAllOnPage = 'ViHeThong/ViGiaoDichChuyenKhoanGetAllOnPage'
	public static ViLoiNhuanThuongGetByID = 'ViHeThong/ViLoiNhuanThuongGetByID'
	public static ViYeuCauTraLoiNhuanThuongGetAllOnPage = 'ViHeThong/ViYeuCauTraLoiNhuanThuongGetAllOnPage'
	public static ViYeuCauTraLoiNhuanThuongUpdateStatus = 'ViHeThong/ViYeuCauTraLoiNhuanThuongUpdateStatus'
	public static ViYeuCauTraLoiNhuanThuongDelete = 'ViHeThong/ViYeuCauTraLoiNhuanThuongDelete'
	public static ViYeuCauTraLoiNhuanThuongGetAllOnPageByDate = 'ViHeThong/ViYeuCauTraLoiNhuanThuongGetAllOnPageByDate'
	
	//LoaiTaiKhoan
	public static LoaiTaiKhoanInsert = 'DmLoaiTaiKhoan/Insert'
	public static LoaiTaiKhoanGetList = 'DmLoaiTaiKhoan/GetList'
	public static LoaiTaiKhoanDelete = 'DmLoaiTaiKhoan/Delete'
	public static LoaiTaiKhoanGetById = 'DmLoaiTaiKhoan/GetById'
	public static LoaiTaiKhoanUpdate = 'DmLoaiTaiKhoan/Update'
	public static LoaiTaiKhoanGetDropDown = 'DmLoaiTaiKhoan/GetDropDown'

	//
	public static ViMerchantUserGetAll = 'ViMerchant/GetAllUser'
	public static ViMerchantInsertAdmin = 'ViMerchant/Insert'
	public static ViMerchantGetListAdmin = 'ViMerchant/GetList'
	public static ViMerchantGetByIdAdmin = 'ViMerchant/GetById'
	public static ViMerchantUpdateAdmin = 'ViMerchant/Update'
	public static ViMerchantDeleteAdmin = 'ViMerchant/Delete'
	public static ViMerchantUpdateStatusAdmin = 'ViMerchant/UpdateStatus'
	public static ViMerchantGetLinhVuc = 'ViMerchant/GetLinhVuc'

	//
	
	public static ThongBaoGetList = 'ThongBao/GetList'
	public static ThongBaoGetByUser = 'ThongBao/GeByUser'
	public static ThongBaoGetById = 'ThongBao/GetById'
	public static ThongBaoInsert = 'ThongBao/Insert'
	public static ThongBaoUpdate = 'ThongBao/Update'
	public static ThongBaoDelete = 'ThongBao/Delete'
	public static ThongBaoUpdateStatus = 'ThongBao/UpdateStatus'
	public static ThongBaoGetDataCreate = 'ThongBao/GetDataCreate'
	//
	public static TinTucGetList = 'TinTuc/GetList'
	public static TinTucGetById = 'TinTuc/GetById'
	public static TinTucInsert = 'TinTuc/Insert'
	public static TinTucUpdate = 'TinTuc/Update'
	public static TinTucDelete = 'TinTuc/Delete'
	public static TinTucUpdateStatus = 'TinTuc/UpdateStatus'
	public static TinTucGetDataCreate = 'TinTuc/GetDataCreate'

	//
	public static DoiSoatGetList = 'DoiSoat/GetList'
	public static DoiSoatGetListChiTiet = 'DoiSoat/GetListChiTiet'
	public static DoiSoatGetById = 'DoiSoat/GetById'
	public static DoiSoatInsert = 'DoiSoat/Insert'
	public static DoiSoatUpdate = 'DoiSoat/Update'
	public static DoiSoatDelete = 'DoiSoat/Delete'
	public static DoiSoatUpdateStatus = 'DoiSoat/UpdateStatus'
	public static DoiSoatUpdateDoiSoat = 'DoiSoat/UpdateDoiSoat'
	public static DoiSoatExportTongHop = 'DoiSoat/ExportTongHop'
	public static DoiSoatExportChiTiet = 'DoiSoat/ExportChiTiet'
}
