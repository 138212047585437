import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { Inject } from '@angular/core'
import { saveAs as importedSaveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr'
import { AppSettings } from '../../constants/app-setting'
import { HttpClient } from '@angular/common/http'
import { Api } from 'src/app/constants/api'
import { UploadFileService } from 'src/app/services/uploadfiles.service'
declare var $: any

@Component({
	selector: 'app-fileview-dialog',
	templateUrl: './fileview-dialog.component.html',
	styleUrls: ['./fileview-dialog.component.css'],
})
export class FileViewDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private filesService: UploadFileService,
		private toastr: ToastrService,
		private http: HttpClient,
		private cdRef: ChangeDetectorRef
	) {}
	downloadId: number = 0
	filename: string = ''
	IsPDF: boolean = false
	IsIMG: boolean = false
	IsShowCreateVersion = false
	files: any
	typeFile: any
	isCanPreview = true
	apiAddress: string;
	apiViewFile: string;
	@ViewChild('pdfViewer', { static: false }) pdfViewer
	listImg: any

	ngOnInit() {
		//debugger
		this.isCanPreview = true
		this.typeFile = this.data.type
		this.apiAddress = this.data.apiaddress;
		this.apiViewFile = this.data.apiviewfile;
		if (this.data.link != null) {
			this.IsPDF = false
			this.IsIMG = false
			this.cdRef.detectChanges()
			// Check nếu là file ảnh thì mở trực tiếp
			// let listsplit = this.data.name;
			// listsplit = listsplit.split('.');
			//listsplit[listsplit.length - 1].toLowerCase();
			let extenfile = this.data.extfile
			//console.log("extenfile: " + extenfile);
			if (
				extenfile == 'png' ||
				extenfile == 'PNG' ||
				extenfile == 'jpg' ||
				extenfile == 'JPG' ||
				extenfile == 'jpeg' ||
				extenfile == 'JPEG' ||
				extenfile == 'gif' ||
				extenfile == 'GIF'
			) {
				this.IsIMG = true
				this.LoadViewImage(this.data.link, this.data.name)
				
			} else if (extenfile == 'pdf' || extenfile == 'PDF') {
				var linkfile = this.data.link
				linkfile = linkfile
				// linkfile = linkfile.replace(AppSettings.API_DOWNLOADFILES, "");
				this.IsPDF = true
				this.IsIMG = false
				this.cdRef.detectChanges()
				this.LoadView(linkfile)
			} else if (
				extenfile == 'doc' ||
				extenfile == 'DOC' ||
				extenfile == 'docx' ||
				extenfile == 'DOCX' ||
				extenfile == 'xls' ||
				extenfile == 'XLS' ||
				extenfile == 'xlsx' ||
				extenfile == 'XLSX'  || 
				extenfile == 'txt' 
			) {
				var linkfile = this.data.link
				linkfile = this.apiViewFile + "/" + linkfile
				console.log("View Link File: " + linkfile + " -- Full: " + AppSettings.VIEW_FILE + btoa(linkfile));
				// linkfile = linkfile.replace(this.apiViewFile, "");
				//$('#viewfile').attr('src', AppSettings.VIEW_FILE + btoa(linkfile))
				$('#viewfile').attr('src', AppSettings.VIEW_FILE + btoa(linkfile))
			} else {
				//console.log("View Link File: " + this.apiViewFile + "/" + this.data.link);
				$('#viewfile').attr('src', this.apiViewFile + "/" + this.data.link)
				this.isCanPreview = false
			}
			var fileurl = this.data.link
			fileurl = fileurl
			// fileurl = fileurl.replace(AppSettings.API_DOWNLOADFILES, "");
			this.filename = fileurl
		}
	}

	setClassFile(item: any) {
		let listsplit = item.name.split('.')
		let extenfile = listsplit[listsplit.length - 1].toLowerCase()

		if (extenfile == 'pdf' || extenfile == 'PDF') {
			return 'fa-file-pdf-o'
		}
		if (extenfile == 'txt' || extenfile == 'TXT') {
			return 'fa-file-text-o'
		}
		if (extenfile == 'doc' || extenfile == 'docx' || extenfile == 'DOC' || extenfile == 'DOCX') {
			return 'fa-file-word-o'
		}
		if (extenfile == 'xls' || extenfile == 'xlsx' || extenfile == 'XLS' || extenfile == 'XLSX') {
			return 'fa-file-excel-o'
		}
		if (
			extenfile == 'png' ||
			extenfile == 'jpg' ||
			extenfile == 'jpeg' ||
			extenfile == 'gif' ||
			extenfile == 'PNG' ||
			extenfile == 'JPG' ||
			extenfile == 'JPEG' ||
			extenfile == 'GIF'
		) {
			return 'fa-file-image-o'
		} else {
			return 'fa-paperclip'
		}
	}

	DownloadFile() {
		
		var req = {
			filePath: this.data.path
		}
	//	var url = this.apiAddress + Api.File_DownloadFile;
		this.filesService.downloadFile(req).subscribe(
			(response) => {
				var blob = new Blob([response], { type: response.type })
				// var extenfile = this.data.extfile
				// if (
				// 	extenfile == 'doc' ||
				// 	extenfile == 'DOC' ||
				// 	extenfile == 'docx' ||
				// 	extenfile == 'DOCX' ||
				// 	extenfile == 'xls' ||
				// 	extenfile == 'XLS' ||
				// 	extenfile == 'xlsx' ||
				// 	extenfile == 'XLSX' ||
				// 	extenfile == 'ppt' ||
				// 	extenfile == 'PPT' ||
				// 	extenfile == 'pptx' ||
				// 	extenfile == 'PPTX' ||
				// 	extenfile == 'pdf' ||
				// 	extenfile == 'PDF' ||
				// 	extenfile == 'png' ||
				// 	extenfile == 'PPTX' ||
				// 	extenfile == 'pdf' ||
				// 	extenfile == 'PDF'
				// ) {
					//importedSaveAs(blob, this.data.name + '.' + this.data.extfile)
					importedSaveAs(blob, this.data.name)
				//}
				
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
		
	}

	LoadView(link) {
		var request = {
			filePath: link
		}
		//var url = this.apiAddress + Api.File_DownloadFile;
		this.filesService.downloadFile(request).subscribe((response) => {
				debugger
				var blob = new Blob([response], { type: response.type })
				this.pdfViewer.pdfSrc = blob
				this.pdfViewer.refresh()
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
	}

	LoadViewImage(link, name) {
		var request = {
			filePath: link,
			fileName: name,
		}
		console.log(request);
		//var url = this.apiAddress + Api.File_GetFileImage;
		this.filesService.GetFileApp(request).subscribe(
			(response) => {
				var blob = new Blob([response], { type: response.type })
				var blob_url = URL.createObjectURL(blob)
				var reader = new FileReader()
				let that = this
				reader.onload = function () {
					var b64 = reader.result.toString().replace(/^data:.+;base64,/, '')
					that.listImg = []
					that.listImg.push(b64)
				}
				reader.readAsDataURL(blob)
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
	}
}
